import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {routeLinks} from "../navigation";
import {Loading} from "../components/Shared/Loading";
import {useAxios} from "../hooks/useAxios";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const VerifyEmail = () => {
  const navigate = useNavigate();
  let query = useQuery();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const {aPost} = useAxios(false);
  

  const sendConfirmation = async (code, email) => {
    return await aPost("/User/verifyEmail", { code, email })
      .then((response) => {
        setLoading(false);
      })
      .catch((response) => {
        setLoading(false);
        setErrorMessage("There was an error validating your account.");
      })
  } 
  
  useEffect(() => {
    let myCode = query.get("code");
    let myEmail = query.get("email");
    if (myCode && myEmail) {
      setLoading(true);
      sendConfirmation(myCode, myEmail);
    }
  }, [])
  
  if (loading) {
    return <Grid container maxWidth="lg" sx={{alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px', paddingY: "200px"}}>
      <Loading/>
    </Grid>
  }
  
  if (errorMessage.length > 0) {
    return <Grid container maxWidth="lg" sx={{alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px', paddingY: "200px"}}>
      <Typography>{errorMessage}</Typography>
    </Grid>
  }
  
  return <Container maxWidth="md" component="main" sx={{paddingY: '30px'}}>
    <Stack spacing={2} alignItems="center">
      <h1>Thank you for verifying your email!</h1>
      <Button variant="contained" onClick={() => navigate(routeLinks.browsePapers)}>Start Browsing Now!</Button>
    </Stack>
  </Container>
}