import * as React from 'react';
import Grid from '@mui/material/Grid';
import {PricingCard} from "./PricingCard";
import {paidTiers} from "./pricingTiers";
import {useAxios} from "../../hooks/useAxios";
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {LoadingBackdrop} from "../Shared/LoadingBackdrop";

export const PaidPricingTiers = () => {
  const [loading, setLoading] = useState(false);
  const [pricingTiers, setPricingTiers] = useState([]);
  const [error, setError] = useState('');
  
  const {aGet} = useAxios(false);
  
  useEffect(() => {
    if (pricingTiers.length <= 0) {
      setLoading(true);
      setPricingTiers([]);
      aGet("/Payment/pricingDetails")
        .then((resp) => {
          if (resp.status !== 200) {
            setError("There was an error fetching our pricing. Please check back later.")
            return;
          }
          
          let pricings = paidTiers;
          
          for (let i = 0; i < pricings.length; i++) {
            let price = pricings[i];
            const respPrice = resp.data.find((x) => {
              return x.stripePriceId == price.priceId;
            });

            price.price = respPrice.stripePrice;
          }
          
          setPricingTiers(pricings);
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [])
  
  
  return (
    <Grid container spacing={5} paddingY={4} alignItems="center" justifyContent="center">
      {loading && <LoadingBackdrop loading={loading}/>}
      {pricingTiers.map((tier) => {
        return <PricingCard tier={tier} key={tier.apiId}/>
      })}
      {error && <Typography>{error}</Typography>}
    </Grid>
  );
}