import React from "react";
import {Box, Button, Container, Paper, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {EducationLevel} from "../components/UserQuiz/EducationLevel";
import {Subject} from "../components/UserQuiz/Subject";
import {useAxios} from "../hooks/useAxios";
import {School} from "../components/UserQuiz/School";
import {useNavigate} from "react-router-dom";

export const UserQuiz = () => {
  const emptyQuiz = {
    educationLevel: null,
    isStudent: false,
    isEducator: false,
    subject: null,
    subjectOther: "",
    affiliatedSchool: "",
    contactName: "",
    contactEmail: "",
    contactPhoneNumber: "",
  }
  const [formState, setFormState] = React.useState(emptyQuiz);
  const [activeStep, setActiveStep] = React.useState(0);
  const [subjectOptions, setSubjectOptions] = React.useState();
  const [educationLevels, setEducationLevels] = React.useState();

  const navigate = useNavigate();

  const {aGet, aPost} = useAxios(true);

  const getSubjectOptions = () => {
    aGet("/UserQuiz/subjectOptions")
      .then((response) => {
        setSubjectOptions(response.data)
      });
  }

  const getEducationLevels = () => {
    aGet("/UserQuiz/educationOptions")
      .then((response) => {
        setEducationLevels(response.data)
      });
  }

  React.useEffect(() => {
    getSubjectOptions();
    getEducationLevels();
  }, [])

  const handleSubmit = () => {
    aPost("/UserQuiz/saveNewUserQuiz", formState)
      .then(() => {
        navigate("/");
      });
  }
  
  const handleNext = () => {
    setActiveStep(activeStep + 1);
    if (activeStep === steps.length - 1) {
      handleSubmit();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  
  const handleLater = () => {
    aPost("/UserQuiz/skippedUserQuiz", {})
      .then(() => {
        navigate("/");
      });
  }

  const steps = ['Education', 'Subject', 'School'];

  const setEducation = (val) => {
    setFormState({...formState, educationLevel: val});
  }

  const setSubject = (val) => {
    setFormState({...formState, subject: val});
  }

  const setSubjectOther = (val) => {
    setFormState({...formState, subjectOther: val});
  }

  const setIsStudent = (val) => {
    setFormState({...formState, isStudent: val});
  }

  const setIsEducator = (val) => {
    setFormState({...formState, isEducator: val});
  }

  const setIsStudentAndEducator = (val) => {
    setFormState({...formState, isStudent: val, isEducator: val})
  }

  const setAffiliatedSchool = (val) => {
    setFormState({...formState, affiliatedSchool: val});
  }

  const setContactName = (val) => {
    setFormState({...formState, contactName: val});
  }

  const setContactEmail = (val) => {
    setFormState({...formState, contactEmail: val});
  }

  const setContactPhoneNumber = (val) => {
    setFormState({...formState, contactPhoneNumber: val});
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <EducationLevel
          educationOptions={educationLevels}
          education={formState.educationLevel}
          setEducation={setEducation}
          isStudent={formState.isStudent}
          setIsStudent={setIsStudent}
          isEducator={formState.isEducator}
          setIsEducator={setIsEducator}
          setIsStudentAndEducator={setIsStudentAndEducator}
        />;
      case 1:
        return <Subject
          subjectOptions={subjectOptions}
          subject={formState.subject}
          setSubject={setSubject}
          subjectOther={formState.subjectOther}
          setSubjectOther={setSubjectOther}
          isStudent={formState.isStudent}
          isEducator={formState.isEducator}
        />;
      case 2:
        return <School
          school={formState.affiliatedSchool}
          setSchool={setAffiliatedSchool}
          isEducator={formState.isEducator}
          contactName={formState.contactName}
          setContactName={setContactName}
          contactEmail={formState.contactEmail}
          setContactEmail={setContactEmail}
          contactPhoneNumber={formState.contactPhoneNumber}
          setContactPhoneNumber={setContactPhoneNumber}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  return <Container component="main" maxWidth="sm" sx={{mb: 4}}>
    <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
      <Typography component="h1" variant="h4" align="center">
        Quiz
      </Typography>
      <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography variant="h5" gutterBottom alignSelf="center">
            Thank you!
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {getStepContent(activeStep)}

          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
              <Button onClick={handleLater} sx={{mt: 3, ml: 1, alignSelf: 'flex-start'}}>
                Maybe later
              </Button>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{mt: 3, ml: 1}}
              >
                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Paper>
  </Container>
}