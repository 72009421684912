import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";

export const EducationLevel = 
  ({
     educationOptions, 
     education, 
     setEducation, 
     isStudent, 
     setIsStudent,
     isEducator,
     setIsEducator,
     setIsStudentAndEducator
   }) => {
  let [currentRoleSelection, setRoleSelections] = React.useState([]);
  const student = "student";
  const educator = "teacher";
  const neither = "neither";
  
  const setTheRoleSelections = () => {
    let array = [];
    if (isStudent) {
      array = [ ...array, student ];
    }
    if (isEducator) {
      array = [ ...array, educator ];
    }

    if (!isStudent && !isEducator) {
      
      array = [ ...array, neither ];
    }

    setRoleSelections(array);
  };
  
  React.useEffect(() => {
    setTheRoleSelections();
  }, [isStudent, isEducator])

  
  const handleFormat = (event, newFormats) => {
    // we selected neither so set both properties to false.
    if (!currentRoleSelection.includes(neither) && newFormats.includes(neither)) {
      setIsStudentAndEducator(false);
      return;
    }

    // selected student
    if (!currentRoleSelection.includes(student) && newFormats.includes(student)) {
      setIsStudent(true);
    }
    // deselected student
    if (!newFormats.includes(student) && currentRoleSelection.includes(student)) {
      setIsStudent(false);
    }
    // selected educator
    if (!currentRoleSelection.includes(educator) && newFormats.includes(educator)) {
      setIsEducator(true);
    }
    // deselected educator
    if (!newFormats.includes(educator) && currentRoleSelection.includes(educator)) {
      setIsEducator(false);
    }
  };

  return <React.Fragment>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>What is your highest level of education?</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Select
            labelId="education-level"
            id="education-level"
            value={education}
            onChange={(event) => setEducation(event.target.value)}
          >
            {educationOptions && educationOptions.map((option) => {
              return <MenuItem value={option.optionId} key={option.optionId}>{option.optionName}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography>Which bests describes you?</Typography>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          color="primary"
          value={currentRoleSelection}
          onChange={handleFormat}
          aria-label="Platform"
        >
          <ToggleButton value={student}>Student</ToggleButton>
          <ToggleButton value={educator}>Teacher</ToggleButton>
          <ToggleButton value={neither}>Neither</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  </React.Fragment>
}