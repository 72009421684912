import React from "react";
import {Box, Button, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const AddEditSubscriptionUsers = ({ editUser, subUser, open, handleClose, onSave }) => {
  const [userState, setUserState] = React.useState(subUser);
  
  React.useEffect(() => {
    setUserState(subUser)
  }, [subUser])
  
  const onIsAdmin = (ev) => {
    setUserState({...userState, isAdmin: ev.target.checked})
  }
  
  return <React.Fragment>
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{editUser ? "Edit User" : "Add User"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>What is their email?</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="contactEmail"
              name={"contactEmail"}
              value={userState.userEmail}
              onChange={(e) => setUserState({...userState, userEmail: e.target.value})}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel 
              control={<Checkbox
                          checked={userState.isAdmin} 
                          label="Is Admin"
                          onChange={(ev) => onIsAdmin(ev)}
                        />}
              label="Is Admin"
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleClose} sx={{mt: 3, ml: 1}}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => onSave(userState)}
              sx={{mt: 3, ml: 1}}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  </React.Fragment>
}