import React from "react";
import {routeLinks} from "../../navigation";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader, List, ListItem} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import CardActions from "@mui/material/CardActions";
import {Link, useLocation} from "react-router-dom";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import {PrimaryContainedButton} from "../Shared/Buttons/PrimaryContainedButton";
import {useUserRightContext} from "../../contexts/userRightContext";

export const PricingCard = ({tier, mdGridColumn = 4, activePlan = false}) => {
  const theme = useTheme();
  const {isLoggedIn} = useUserRightContext();
  const location = useLocation();

  let link = routeLinks.checkout + "/" + tier.priceId
  const includeRetUrl = location.pathname.includes(routeLinks.paper.split("/").length > 2 ? routeLinks.paper.split("/")[1] : false);
  if (!isLoggedIn) {
    link = routeLinks.signup + "?retUrl=" + link.replace("/", "");
  } else {
    link += includeRetUrl ? "?retUrl=" + location.pathname.replace("/", "") : "";
  }
  
  const isFree = tier.price === 'free';

  const renderButton = (tier) => {
    return tier.buttonVariant === "contained"
      ? <PrimaryContainedButton text={tier.buttonText} fullWidth disableButton={tier.disableButton}/>
      : <Button
        fullWidth
        variant={tier.buttonVariant}
        disabled={tier.disableButton}
      >
        {tier.buttonText}
      </Button>
  }

  return <Grid
    item
    key={tier.title}
    xs={12}
    md={mdGridColumn}
  >
    <Card sx={{width: "100%"}}>
      {
        tier.isPopular && <CardHeader
          title={"Most popular plan"}
          titleTypographyProps={{align: 'center', fontSize: ".8rem", fontWeight: "600"}}
          subheaderTypographyProps={{
            align: 'center',
          }}
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.main,
            color: (theme) => theme.palette.font.white,
            padding: ".5rem",
            fontSize: ".5rem"
          }}
        />
      }
      <CardContent sx={{display: 'flex', flexDirection: "column"}}>
        <Typography sx={{
          textAlign: "center",
          fontSize: "1.15rem",
          fontWeight: "500",
          padding: ".85rem"
        }}>{tier.title}</Typography>
        {!isFree && <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
          <Typography sx={{fontSize: "2.5rem", fontWeight: "500"}} color="text.primary">
            ${tier.price}
          </Typography>
          <Typography sx={{fontSize: "1rem", fontWeight: "500"}} color="text.secondary">
            /month
          </Typography>
        </Box>}
        <List>
          {tier.description.map((line, index) => (
            <Box sx={{display: "flex"}} key={index}>
              <FontAwesomeIcon style={{paddingTop: ".75rem"}} color={theme.palette.primary.main} icon={faCircleCheck}/>
              <ListItem
                variant="subtitle1"
                align="center"
                sx={{fontSize: ".85rem"}}
              >
                {line}
              </ListItem>
            </Box>
          ))}
        </List>
      </CardContent>
      <CardActions>
        {
          tier.disableButton
            ? <Button
              fullWidth
              variant={tier.buttonVariant}
              disabled={tier.disableButton}
            >
              {tier.buttonText}
            </Button>
            :
            tier.hasButtonOnClick
              ? <div style={{width: '100%'}} onClick={() => tier.buttonOnClick(tier.name)}>
                {
                  renderButton(tier)
                }
              </div>
              : <Link style={{width: '100%'}} to={link}>
                {
                  renderButton(tier)
                }
              </Link>
        }
      </CardActions>
    </Card>
  </Grid>
}