import {createContext, useContext, useEffect, useState} from "react";
import {useMediaQuery} from "@mui/material";

export const darkToken = 'dark';
export const lightToken = 'light';

export const MyThemeContext = createContext();

export const MyThemeProvider = ({children}) => {
  const preferredMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // useEffect(() => {
  //   setIsDarkMode(preferredMode)
  // }, [preferredMode])
  
  return <MyThemeContext.Provider
    value={{
      isDarkMode,
      setIsDarkMode
    }}
  >
    {children}
  </MyThemeContext.Provider>
}

export const useMyThemeContext = () => useContext(MyThemeContext);
