import {createContext, useContext} from "react";
import {useAxios} from "../hooks/useAxios";
import {useUserRightContext} from "./userRightContext";

export const PaperContext = createContext();

export const PaperProvider = ({children}) => {
  const {aGet} = useAxios(true);
  let {isLoggedIn} = useUserRightContext();

  const getPaper = async (paperId) => {
    return await aGet("/Papers/" + (isLoggedIn ? "getPaper" : "getPaperPreview") + "?paperId=" + paperId)
      .then(resp => {
        if (resp.status !== 200)
          throw Error(resp.data)

        return resp.data;
      })
      .catch(err => {
        console.log("Papers response error...." + err);
        throw err;
      })

  }

  return <PaperContext.Provider value={{getPaper}}>
    {children}
  </PaperContext.Provider>
}

export const usePaperContext = () => useContext(PaperContext);