import {createContext, useContext, useState} from "react";

export const UserRightContext = createContext();

export const UserRightProvider = ({children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasLoggedInBefore, setHasLoggedInBefore] = useState(false);
  const [isSchoolAdmin, setIsSchoolAdmin] = useState(false);
  const [userId, setUserId] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [hasLoadedUser, setHasLoadedUser] = useState(false);
  
  return <UserRightContext.Provider
    value={{
      isLoggedIn,
      setIsLoggedIn,
      userId,
      setUserId,
      isSchoolAdmin, 
      setIsSchoolAdmin,
      hasLoggedInBefore,
      setHasLoggedInBefore,
      subscriptionType,
      setSubscriptionType,
      hasLoadedUser, 
      setHasLoadedUser,
    }}
  >
    {children}
  </UserRightContext.Provider>
}

export const useUserRightContext = () => useContext(UserRightContext);