import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {routeLinks, websiteLinks} from "../navigation";
import {useUser} from "../contexts/userContext";
import {GoogleLogin} from "@react-oauth/google";
import {useLocation, useNavigate} from "react-router-dom";
import {useToastify} from "../hooks/useToastify";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SignUp() {
  let { signUp, logInGoogle } = useUser();
  let blankUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rememberMe: true
  }
  const [formState, setFormState] = React.useState(blankUser);
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  
  
  const navigate = useNavigate();
  const {successToast, errorToast} = useToastify();
  let query = useQuery();

  const validateEmail = (val) => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    let isValid = val?.match(emailRegex);
    setIsEmailValid(isValid);
    return isValid;
  }
  const validatePassword = () => {
    console.log("are we validating password??")
    let shouldMeetPasswordRequirements = /^(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/;
    let meetRequirements = formState?.password?.match(shouldMeetPasswordRequirements)
    if (!meetRequirements) {
      setPasswordError("Password has to have at least 6 characters, including at least one non-alphanumeric character, one digit, and one uppercase letter.");
      return false;
    }
    setPasswordError("");
    return true;
  }

  const onChangeEmail = (e) => {
    validateEmail(e.target.value);
    setFormState({ ...formState, email: e.target.value });
  }
  const onChangePassword = (e) => {
    setFormState({ ...formState, password: e.target.value });
  }
  const onChangeFirst = (e) => {
    setFormState({ ...formState, firstName: e.target.value });
  }
  const onChangeLast = (e) => {
    setFormState({ ...formState, lastName: e.target.value });
  }
  const onRememberMe = (checked) => {
    setFormState({ ...formState, rememberMe: checked });
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    let validEmail = validateEmail(formState.email);
    let validPassword = validatePassword();
    
    if (!validEmail || !validPassword) return;
    
    let uO = {
      email: formState.email,
      password: formState.password,
      firstName: formState.firstName,
      lastName: formState.lastName,
      rememberMe: formState.rememberMe
    }

    await signUp(uO.email, uO.password, uO.firstName, uO.lastName, uO.rememberMe)
      .then((response) => {
        if (response?.status === 400) {
          if (response.data) {
            errorToast(response.data);
          } else {
            errorToast("Unable to log you in. Please try again.");
          }
        } else {
          successToast("Thank you for signing up!");
          if (query.get("retUrl")) {
            navigate("/" + query.get("retUrl"));
          } else {
            navigate("/");
          }
        }
      })
      .catch((response) => {
        if (response.data) {
          errorToast(response.data);
        } else {
          errorToast("Unable to sign up. Please try again.");
        }
      })
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={formState.firstName}
                  onChange={(e) => onChangeFirst(e)} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={formState.lastName}
                  onChange={(e) => onChangeLast(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formState.email}
                  onChange={(e) => onChangeEmail(e)}
                  error={!isEmailValid}
                  helperText={!isEmailValid && "Please provide a valid email address."}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={formState.password}
                  onChange={(e) => onChangePassword(e)}
                  error={passwordError.length > 0}
                  helperText={passwordError.length > 0 && passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={formState.rememberMe} onChange={(e) => onRememberMe(e.target.checked)} value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
            </Grid>
            <Grid>
              <Typography sx={{ fontSize: ".9rem" }}>
                By clicking on sign-up, you agree to our <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.termsOfService} target="_blank">Terms and Conditions</Link>.
              </Typography>
              <Typography sx={{ fontSize: ".9rem" }}>
                To learn more about how we collect, use, share, and protect your personal data, please see our <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.privacy} target="_blank">Privacy Policy</Link>.
              </Typography>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleSubmit(e)}
            >
              Sign Up
            </Button>
            {/*<GoogleLogin*/}
            {/*  text="signup_with"*/}
            {/*  onSuccess={credentialResponse => {*/}
            {/*    logInGoogle(credentialResponse.credential, "post", "something")*/}
            {/*    console.log(credentialResponse);*/}
            {/*  }}*/}
            {/*  onError={() => {*/}
            {/*    console.log('Google Login Failed');*/}
            {/*  }}*/}
            {/*/>*/}
            <Grid container justifyContent="flex-end" style={{ paddingTop: "20px"}}>
              <Grid item>
                <Link href={routeLinks.login} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}