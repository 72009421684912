import React from "react";
import {Pagination as MuiPag} from "@mui/material";

export const Pagination = ({ page, numberOfPages, onPageChange }) => {
  return <MuiPag 
    page={page} 
    count={numberOfPages} 
    onChange={(e, val) => onPageChange(val)} 
    color="secondary" 
    variant="outlined"  
  />
}