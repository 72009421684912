import React, {createContext, useContext, useEffect, useState} from "react";
import {useAxios} from "../hooks/useAxios";
import Cookies from 'js-cookie';
import {useToastify} from "../hooks/useToastify";
import {useUserRightContext} from "./userRightContext";

export const UserContext = createContext();

const UserProvider = ({children}) => {
  const [jwtToken, setJwtToken] = useState("");
  const jwtTokenName = "journal-auth";
  const userIdName = "user-id";
  const hasLoggedInBeforeName = "user-has-logged-in-before";
  
  const {aGet, aPost} = useAxios(false);
  const {successToast, errorToast} = useToastify();
  const { 
    setIsSchoolAdmin, 
    setIsLoggedIn, 
    setUserId,
    setHasLoggedInBefore,
    setSubscriptionType,
    setHasLoadedUser
  } = useUserRightContext();


  const getUserCookies = () => {
    let jwt = Cookies.get(jwtTokenName);
    let userIdCookie = Cookies.get(userIdName);
    let hasLoggedInBeforeCookie = Cookies.get(hasLoggedInBeforeName);

    return {
      jwt,
      userIdCookie,
      hasLoggedInBeforeCookie
    }
  }
  const setUserCookies = (userId, jwtToken) => {
    Cookies.set(jwtTokenName, jwtToken, {expires: 6});
    Cookies.set(userIdName, userId, {expires: 6});
    Cookies.set(hasLoggedInBeforeName, true, {expires: 365});
  }
  const removeUserCookies = () => {
    Cookies.remove(jwtTokenName);
    Cookies.remove(userIdName);
  }
  
  const setUserDetails = async () => {
    let { jwt, userIdCookie, hasLoggedInBeforeCookie } = getUserCookies();
    
    await aGet("/User/userDetails?userId=" + userIdCookie)
      .then((resp) => {
        console.log("get user details???", resp)
        if (resp.status === 200 && resp.data != null) {
          let userD = resp.data;
          setIsSchoolAdmin(userD.isSchoolAdmin);
          setSubscriptionType(userD.subscriptionType);
        }
      })
      .catch((err) => {
        setIsLoggedIn(false);
      })
      .finally(() => {
        setHasLoadedUser(true);
      })
  }

  const checkIfUserIsLoggedIn = async () => {
    let { jwt, userIdCookie, hasLoggedInBeforeCookie } = getUserCookies();
    
    if (hasLoggedInBeforeCookie)
      setHasLoggedInBefore(hasLoggedInBeforeCookie);
    
    if (jwt !== undefined && jwt !== null && userIdCookie !== undefined && userIdCookie !== null) {
      setJwtToken(jwt);
      setUserId(userIdCookie);
      setIsLoggedIn(true);
      
      //here
      await setUserDetails();
    } else {
      setIsLoggedIn(false);
      setHasLoadedUser(true);
    }
  }
  
  useEffect(() => {
    checkIfUserIsLoggedIn();
  }, []);

  const signUp = async (email, password, firstName, lastName, rememberMe) => {
    return await aPost("/User/register", { email, password, firstName, lastName })
      .then(resp => {
        console.log('response: ', resp)
        if (resp.status !== 200) {
          console.log("this response was bad we are throwing an error")
          throw resp.data;
        }
        
        setIsLoggedIn(true);
        setJwtToken(resp.data.token);
        setUserId(resp.data.userId);

        if (rememberMe) {
          setUserCookies(resp.data.userId, resp.data.token);
        }
        
        return resp.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  const logIn = async (email, password, rememberMe) => {
    return await aPost("/User/login", { email, password })
      .then(resp => {
        console.log("login response: ", resp)
        let data = resp.data;
        
        setIsLoggedIn(true);
        setJwtToken(data.token);
        setUserId(data.userId)
        setIsSchoolAdmin(data.isSchoolAdmin);
        setSubscriptionType(data.subscriptionType)
        
        if (rememberMe) {
          setUserCookies(resp.data.userId, resp.data.token);
        }
        
        return resp.data;
      })
      .catch((error) => {
        console.log("logIn error!", error);
        return error.response;
      })
  }
  
  const logInGoogle = async (token) => {
    return await aPost("/User/google", { token })
      .then(resp => {
        setIsLoggedIn(true);
        setJwtToken(resp.data.token);
        
        // todo: google remember me?
        
        return true;
      })
      .catch(resp => {
        console.log("google login failed!");
        return resp;
      });
  }
  
  const forgotPassword = async (email) => {
    return await aPost("/User/startResetPassword/" + email)
      .then((resp) => {
        successToast("Instructions to reset your password have been sent to your email.");
        return resp;
      })
      .catch((resp) => {
        errorToast("Unable to send reset password email. Please try again later.");
        return resp;
      })
  }
  
  const submitforgotPassword = async (email, password, token) => {
    return await aPost("/User/submitResetPassword", { email, password, token })
      .then((response) => {
        return true;
      })
      .catch((resp) => {
        errorToast("Unable to reset password. Please try again later.");
        return false;
      })
  }

  const logOut = () => {
    setIsLoggedIn(false);
    setJwtToken(null);
    setUserId(null);
    setIsSchoolAdmin(false);
    
    removeUserCookies();
  }
  
  return <UserContext.Provider
    value={{
      logIn,
      logInGoogle,
      logOut,
      signUp,
      forgotPassword,
      submitforgotPassword,
      jwtToken,
      setUserDetails
    }}
  >
    {children}
  </UserContext.Provider>
}

export const useUser = () => useContext(UserContext);

export default UserProvider;