import React, { useContext } from "react";
import axios from "axios";
import {UserContext, useUser} from "../contexts/userContext";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const useAxios = (useToken = true) => {
  // data, error, loading

  let user = useUser();
  let config = {};

  if (useToken) {
    axios.defaults.headers.common['Authorization'] = user?.jwtToken;
    // config.headers.Authorization
    config = {...config, headers: {
        'Authorization': user?.jwtToken
      }}
  }

  const aGet = async (url) => await axios.get(url);
  const aPost = async (url, body) => await axios.post(url, body, config);
  const aPut = async (url, body) => await axios.put(url, body);
  const aDelete = async (url) => await axios.delete(url);

  return { aGet, aPost, aPut, aDelete };
}