import React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {PrimaryContainedButton} from "../Shared/Buttons/PrimaryContainedButton";
import {Link, useLocation} from "react-router-dom";
import {routeLinks} from "../../navigation";

export const FreePlanPrompt = () => {
  const theme = useTheme();
  const location = useLocation();
  
  const includeRetUrl = location.pathname.includes(routeLinks.paper.split("/").length > 2 ? routeLinks.paper.split("/")[1] : false);

  return <Container maxWidth="lg" component="main">
    <Grid container spacing={5} paddingY={4} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Typography sx={{
          color: theme.palette.font.grey,
          fontSize: '1rem',
          fontWeight: "600",
          textAlign: 'center'
        }}>Rapidemia</Typography>
        <Typography sx={{
          fontSize: '3.2rem',
          fontWeight: "700",
          textAlign: 'center',
          paddingTop: ".9rem"
        }}>Get started for free</Typography>
        <Typography sx={{
          fontSize: '1.15rem',
          textAlign: 'center',
          paddingTop: "1.1rem"
        }}>Two free articles a month.</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <Link to={routeLinks.signup + (includeRetUrl ? "?retUrl=" + location.pathname.replace("/", "") : "")}>
        <PrimaryContainedButton text='Get started' size='medium' sx={{
          margin: '10px'
        }}/></Link>
      </Grid>
    </Grid>
  </Container>
}