import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

export const YesNoToggle = ({yes, setYes}) => {
  const changeToggle = (e, newVal) => {
    setYes(newVal === 'yes')
  }
  return <ToggleButtonGroup
    color="primary"
    value={yes ? 'yes' : 'no'}
    onChange={changeToggle}
    aria-label="Platform"
    exclusive
  >
    <ToggleButton value="yes">Yes</ToggleButton>
    <ToggleButton value="no">No</ToggleButton>
  </ToggleButtonGroup>
}