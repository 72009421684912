import React from "react";
import Button from "@mui/material/Button";

export const PrimaryContainedButton = ({ text, sx = {}, size = 'small', fullWidth = false, disableButton = false }) => {
  return <Button
    fullWidth={fullWidth}
    variant='contained'
    size={size}
    sx={{
      color: (theme) => theme.palette.font.white,
      ...sx
    }}
    disabled={disableButton}
  >
    {text}
  </Button>
}