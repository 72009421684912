import {createContext, useContext} from "react";
import {useAxios} from "../hooks/useAxios";

export const PapersContext = createContext();

export const PapersProvider = ({children}) => {
  const {aGet} = useAxios(false);
  const randomNumber = Math.floor(Math.random() * 6541);
  
  const getPapers = async (page, pageCount, titleSearch, paperSubjectIds) => {
    let subjectSection = paperSubjectIds.reduce((prev, val) => prev + '&PaperSubjectIds=' + val, '');
    let titleSelection = titleSearch ? "&TitleSearch=" + titleSearch : "";
    let url = "/Papers/getPapersWithFiltersSeed?" + "RecordsPerPage=" + pageCount + "&PageNumber=" + page + titleSelection + subjectSection + "&OrderSeed=" + randomNumber;
    return await aGet(url)
      .then(resp => {
        if (resp.status !== 200)
          throw Error(resp.data);
        return resp.data;
      })
      .catch(err => {
        throw err;
      })
  }
  
  return <PapersContext.Provider value={{getPapers}}>
    {children}
  </PapersContext.Provider>
}

export const usePapersContext = () => useContext(PapersContext);