import React from "react";
import {Container, Grid, Typography} from "@mui/material";
import {PaidPricingTiers} from "./PaidPricingTiers";
import {useTheme} from "@mui/material/styles";

export const PaidPricingPlans = () => {
  const theme = useTheme();

  return <Container maxWidth="lg" component="main">
    <Grid container spacing={5} paddingY={4} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Typography sx={{
          color: theme.palette.primary.main,
          fontSize: '1rem',
          fontWeight: "600",
          textAlign: 'center'
        }}>Pricing</Typography>
        <Typography sx={{
          fontSize: '3.2rem',
          fontWeight: "700",
          textAlign: 'center',
          paddingTop: ".9rem"
        }}>Our Pricing Plans</Typography>
        <Typography sx={{
          fontSize: '1.15rem',
          textAlign: 'center',
          paddingTop: "1.1rem"
        }}>Elevate Your Academic Journey with Rapidemia</Typography>
      </Grid>
      <Grid item xs={12}>
        <PaidPricingTiers/>
      </Grid>
    </Grid>
  </Container>
}