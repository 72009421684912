import {darkToken} from "../contexts/myThemeContext";

//example of override
// {
//   components: {
//     // the component name defined in the `name` parameter
//     // of the `styled` API
//     MuiStat: {
//       styleOverrides: {
//         // the slot name defined in the `slot` and `overridesResolver` parameters
//         // of the `styled` API
//         root: {
//           backgroundColor: theme.palette.grey,
//         },
//         value: {
//           color: '#fff',
//         },
//         unit: {
//           color: '#888',
//         },
//       },
//     },
//   },
// };

export const lightTheme = {
  palette: {
    primary: {
      main: '#2BEF3D',
    },
    secondary: {
      main: '#7bc7e5',
    },
    background: {
      default: "#FFF"
    },
    font: {
      main: "rgb(0, 0, 0)",
      grey: "rgb(71, 84, 103)",
      darkGrey: "rgb(52, 64, 84)",
      white: "#FFF"
    }
  },
  typography: {
    fontFamily: ["Open Sans, sans-serif"]
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#FFF'
        }
      }
    }
  }
};

export const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: '#2BEF3D',
    },
    secondary: {
      main: '#5B2CA3',
    },
    background: {
      default: '#1D1D1D',
    },
    // TODO: These need to change...
    font: {
      main: "rgb(0, 0, 0)",
      grey: "rgb(71, 84, 103)",
      darkGrey: "rgb(52, 64, 84)",
      white: "#FFF"
    }
  },
  typography: {
    fontFamily: ["Open Sans, sans-serif"]
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#1D1D1D'
        }
      }
    }
  }
}



export const getDesignByMode = (mode) => {
  return {
      ...(mode === darkToken ? darkTheme : lightTheme)
  };
}