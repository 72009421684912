import React from "react";
import {Grid, TextField, Typography} from "@mui/material";
import {YesNoToggle} from "../Shared/YesNoToggle";

export const School =
  ({
     school,
     setSchool,
     isEducator,
     contactEmail, 
     setContactEmail,
     contactName,
     setContactName,
     contactPhoneNumber,
     setContactPhoneNumber
   }) => {
    const [contactSchool, setContactSchool] = React.useState(false);
    return <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>What school or university are you affiliated with?</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="school"
            name={"school"}
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            fullWidth
          />
        </Grid>
        {isEducator && <React.Fragment>
          <Grid item xs={12}>
            <Typography>Would you like us to contact you about partnering with your University?</Typography>
          </Grid>
          <Grid item xs={12}>
            <YesNoToggle setYes={setContactSchool} yes={contactSchool}/>
          </Grid>
        </React.Fragment>}
        {isEducator && contactSchool && <React.Fragment>
          <Grid item xs={12}>
            <Typography>Who after we contacting?</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="contactName"
              name={"contactName"}
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>What is their email?</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="contactEmail"
              name={"contactEmail"}
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>What is their phone number?</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="contactPhoneNumber"
              name={"contactPhoneNumber"}
              value={contactPhoneNumber}
              onChange={(e) => setContactPhoneNumber(e.target.value)}
              fullWidth
            />
          </Grid>
        </React.Fragment>}
      </Grid>
    </React.Fragment>
  }