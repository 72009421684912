import React from "react";
import {useMyThemeContext} from "../contexts/myThemeContext";
import {toast} from "react-toastify";

export const useToastify = () => {
  const {isDarkMode} = useMyThemeContext();

  const successToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: isDarkMode ? "dark" : "light",
    });
  }

  const infoToast = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: isDarkMode ? "dark" : "light",
    });
  }

  const warningToast = (message) => {
    toast.warn(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: isDarkMode ? "dark" : "light",
    });
  }

  const errorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: isDarkMode ? "dark" : "light",
    });
  }

  return {successToast, infoToast, warningToast, errorToast};
}