import React from "react";
import {Button, Container, Grid, TextField, Typography} from "@mui/material";
import {useAxios} from "../hooks/useAxios";
import {useToastify} from "../hooks/useToastify";
import {LoadingBackdrop} from "../components/Shared/LoadingBackdrop";

export const HelpPage = () => {
  let [name, setName] = React.useState("");
  let [emailAddress, setEmailAddress] = React.useState("");
  let [subject, setSubject] = React.useState("");
  let [body, setBody] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const {aPost} = useAxios(true);
  const {successToast, errorToast} = useToastify();

  const validateEmail = () => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return emailAddress.match(emailRegex);
  }
  
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      setLoading(true);
      if (!validateEmail(emailAddress)) {
        errorToast("Please enter a valid email address.");
        return;
      }

      let response = await aPost('/User/emailHelp', {
        name,
        email: emailAddress,
        subject,
        body
      });

      if (response.status === 200) {
        successToast("Thank you for reaching out. We will be in touch soon!");
      } else {
        errorToast("We are experiencing an error please. Try reaching out to support@rapidemia.com directly.");
      }
      setLoading(false);
    }
    catch(err) {
      setLoading(false);
      errorToast("We are experiencing an error please. Try reaching out to support@rapidemia.com directly.");
    }
  }
  
  return <Container component="main" maxWidth="sm"
                    sx={{paddingY: "65px", display: "flex", justifyContent: "center", alignItems: "center"}}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {loading && <LoadingBackdrop loading={loading}/>}
        <Typography sx={(theme) => {
          return {color: theme.palette.font.grey, fontSize: "1.1rem", fontWeight: "500"}
        }}>
          Get In Touch
        </Typography>
        <Typography component="h1" variant="h3" sx={{ fontWeight: "600", paddingY: ".75rem" }}>
          Contact Our Team
        </Typography>
        <Typography sx={{lineHeight: "2rem"}}>
          Whether you have a question, need assistance, or simply want to share your feedback, we are here to listen. We strive to provide you with the best possible experience and your input helps us to continually improve.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Name</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="name"
          name={"Name"}
          placeholder={"jane smith"}
          value={name}
          fullWidth
          onChange={(ev) => {
            setName(ev.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Email</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          name={"Email"}
          placeholder={"jane smith"}
          value={emailAddress}
          fullWidth
          onChange={(ev) => {
            setEmailAddress(ev.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Subject</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="subject"
          name={"Subject"}
          value={subject}
          fullWidth
          onChange={(ev) => {
            setSubject(ev.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Body</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="body"
          name={"Body"}
          value={body}
          multiline
          minRows={4}
          fullWidth
          onChange={(ev) => {
            setBody(ev.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{mt: 3, ml: 1}}
      >
        Submit
      </Button>
      </Grid>
    </Grid>
  </Container>;
}