import React from 'react';
import Container from "@mui/material/Container";
import {PaperCard} from "./PaperCard";
import {PaperFilters} from "./PaperFilters";
import {Box, Grid} from "@mui/material";
import {Pagination} from "../Shared/Pagination";
import {usePapersContext} from "../../contexts/papersContext";
import {useToastify} from "../../hooks/useToastify";
import {Loading} from "../Shared/Loading";
import {usePagination} from "../../hooks/usePagination";
import {delayFunction} from "../../utils/debounce";

export const Browse = () => {
  let [papers, setPapers] = React.useState(null);
  let [hasError, setHasError] = React.useState(false);
  const [filters, setFilters] = React.useState({title: "", paperSubjectIds: []});
  const [loading, setLoading] = React.useState(true);
  const [firstLoading, setFirstLoading] = React.useState(true);

  let {getPapers} = usePapersContext();
  let {errorToast} = useToastify();
  let {page, recordsPerPage, setPage, pageCount, totalRecords, setTotalRecords} = usePagination();

  const changeTitleFilter = (val) => {
    setFilters({...filters, title: val});
  }

  const subjectOptionsUpdate = (values) => {
    setFilters({...filters, paperSubjectIds: values})
  }

  const handlePapers = async () => {
    setLoading(true);
    setPapers([]);
    const reduceSubjects = filters.paperSubjectIds.reduce((prev, val) => [...prev, val.paperSubjectId], [])
    await getPapers(page, recordsPerPage, filters.title, reduceSubjects)
      .then((response) => {
        setPapers(response.data);
        setTotalRecords(response.totalRecords)
      })
      .catch(err => {
        errorToast("something went wrong. Please try again");
        setPapers([]);
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
      handlePapers();
    } else {
      delayFunction("browse-papers", () => handlePapers(), 500);
    }
  }, [page, filters]);

  return <Container component="main" maxWidth="lg"
                    sx={{paddingY: "65px", display: "flex", justifyContent: "center", alignItems: "center"}}>
    {hasError && <Grid>
      We are experiencing an issue... Please try again later.
    </Grid>}
    {!hasError && !papers && <Loading/>}
    {!hasError && papers
    && <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        {
          loading ? <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}><Loading/></Grid> :
            papers.length > 0
          ? <Grid container spacing={1} alignItems="stretch">
            {
              papers.map((item, index) => {
                return <Grid style={{display: 'flex'}} item xs={12} md={6} key={index}>
                  <PaperCard paper={item}/>
                </Grid>
              })
            }
          </Grid>
          : <Grid sx={{textAlign: "center"}}>No Results Found</Grid>}
        <Box alignItems={'center'} justifyContent={'center'} sx={{display: "flex", padding: "20px"}}>
          <Pagination
            page={page}
            numberOfPages={pageCount}
            onPageChange={(val) => setPage(val)}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <PaperFilters
          filters={filters}
          changeTitleFilter={changeTitleFilter}
          subjectOptionsUpdate={subjectOptionsUpdate}
        />
      </Grid>
    </Grid>
    }
  </Container>
}