import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useUser} from "../contexts/userContext";
import {useToastify} from "../hooks/useToastify";
import {useQuery} from "../hooks/useQuery";
import {Grid} from "@mui/material";
import {LoadingBackdrop} from "../components/Shared/LoadingBackdrop";

export const ResetPassword = () => {
  const { submitforgotPassword } = useUser();
  let query = useQuery();

  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState(query.get("email"));
  const [token, setToken] = React.useState(query.get("token"));
  const [submittedPasswordReset, setSubmittedPasswordReset] = React.useState(false);

  const {errorToast} = useToastify();
  

  const handleResetPassword = async (e) => {
    if (!email || !token) {
      errorToast("We had an issue with resetting your password. Please try agian later or use the help button at the bottom of the page.");
      return;
    }
    setLoading(true);
    e.preventDefault();
    await submitforgotPassword(email, password, token)
      .then((response) => {
        if (response) {
          setSubmittedPasswordReset(true);
        } 
        setLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {loading && <LoadingBackdrop loading={loading}/>}
        {!submittedPasswordReset ? <React.Fragment>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleResetPassword(e)}
            >
              Reset Password
            </Button>
          </Box>
          </React.Fragment> : <React.Fragment>
            <Grid container maxWidth="lg" sx={{ marginY: "150px", alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px' }}>
              <Typography variant="h4">We sent instructions to your email for next steps on resetting your password. Thank you!</Typography>
            </Grid>
        </React.Fragment>}
      </Box>
    </Container>
  );
}

