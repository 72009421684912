import * as React from 'react';
import {useMemo} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {Menu, MenuItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routeLinks} from "../../navigation";
import {useUserRightContext} from "../../contexts/userRightContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useUser} from "../../contexts/userContext";
import {apiUnlimitedTierId, unlimitedTier} from "../Pricing/pricingTiers";

function ResponsiveAppBar() {
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  let {isLoggedIn, isSchoolAdmin, hasLoggedInBefore, subscriptionType} = useUserRightContext();
  let { logOut } = useUser();
  const settings = useMemo(() => {
    let links = [
      {name: "Browse", link: routeLinks.browsePapers},
      {name: 'Settings', link: routeLinks.settings}
    ];

    if (isSchoolAdmin)
      links = [...links, isSchoolAdmin && {name: "School Users", link: routeLinks.subscriptionUsers}]

    links = [...links, {name: "Logout", link: "/logout"}]
    
    return links;
  }, [isSchoolAdmin]);

  // User icon menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (link) => {
    if (link === "/logout") {
      logOut();
      navigate(routeLinks.browsePapers);
    } else {
      navigate(link)
    }
    
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography 
            variant="h6" 
            component="div" 
            sx={(theme) => ({flexGrow: 1, color: theme.palette.font.main, display: 'flex',fontWeight: 500})}
            onClick={() => navigate(routeLinks.browsePapers)}
          >
            <img src={'https://d28skfm1ycccok.cloudfront.net/rapidemia-white-logo.png'} style={{width: '30px', height: '30px'}} />
            Rapidemia
          </Typography>

          <Box>
            {
              isLoggedIn ?
                <Box sx={{flexGrow: 0}}>
                  {subscriptionType !== apiUnlimitedTierId && <Button variant="contained"  sx={{marginRight: "1rem"}} onClick={() => navigate(routeLinks.yourSubscription)}>Upgrade
                    plan</Button>}
                  <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <FontAwesomeIcon icon={faBars}/>
                  </IconButton>
                  <Menu
                    sx={{mt: '45px'}}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => setAnchorElUser(null)}
                  >
                    {settings.map((setting) => {
                      return <MenuItem key={setting.name} onClick={() => handleCloseUserMenu(setting.link)}>
                        <Typography textAlign="center">{setting.name}</Typography>
                      </MenuItem>
                    })}
                  </Menu>
                </Box>
                : (
                  hasLoggedInBefore ?
                    <Button variant="outlined" onClick={() => navigate(routeLinks.login)}>Login</Button>
                    : <Button variant="outlined" onClick={() => navigate(routeLinks.signup)}>Sign Up</Button>
                )
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;