import React from "react";

export const FieldFactory = ({contents}) => {
  
  const buildElement = (level, content, index) => {
    switch(level.toLowerCase()) {
      case "h1":
        return <h1 key={index}>{content}</h1>
      case "h2":
        return <h2 key={index}>{content}</h2>
      case "h3":
        return <h3 key={index}>{content}</h3>
      case "h4":
        return <h4 key={index}>{content}</h4>
      case "h5":
        return <h5 key={index}>{content}</h5>
      case "h6":
        return <h6 key={index}>{content}</h6>
      case "p":
        return <p key={index}>{content}</p>
      default:
        return <p key={index}>{content}</p>
    }
  }
  
  return <React.Fragment>
    {contents && contents.map((content, parentIndex) => {
      return <React.Fragment>
        {buildElement(content.level, content.heading, parentIndex)}
        {content.text.includes("<p>") ? content.text.split("<p>").map((contentSpiltOne, childIndex) => {
          if (contentSpiltOne) {
            let contentSpiltTwo = contentSpiltOne.split("</p>");
            return buildElement("p", contentSpiltTwo[0], parentIndex + childIndex);
          }
        }) : buildElement("p", content.text, parentIndex * 100)}
        {content.subsections && <FieldFactory contents={content.subsections} />}
      </React.Fragment>
    })}
  </React.Fragment>
};