import React from "react";
 
export const usePagination = () => {
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = React.useState(0);
  
  React.useEffect(() => {
    let pageCount = Math.ceil(totalRecords / recordsPerPage);
    setPageCount(pageCount)
  }, [totalRecords])
  
  return { page, recordsPerPage, setPage, pageCount, totalRecords, setTotalRecords };
}