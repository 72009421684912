import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Login} from "../pages/Login";
import {NotFound} from "../pages/NotFound";
import SignUp from "../pages/SignUp";
import {useMyThemeContext} from "../contexts/myThemeContext";
import {createTheme, ThemeProvider, Toolbar} from "@mui/material";
import {getDesignByMode} from "../theme/theme";
import {SubscriptionConfirmation} from "../pages/SubscriptionConfirmation";
import ResponsiveAppBar from "../components/Header";
import {UserQuiz} from "../pages/UserQuiz";
import CssBaseline from "@mui/material/CssBaseline";
import {Settings} from "../pages/Settings";
import {SubscriptionUsers} from "../components/Settings/AdminPanel/SubscriptionUsers";
import {ResetPassword} from "../pages/ResetPassword";
import {useUserRightContext} from "../contexts/userRightContext";
import {BrowsePage} from "../pages/Browse";
import {PaperPage} from "../pages/Paper";
import {Pricing} from "../pages/Pricing";
import {YourSubscription} from "../components/Settings/UserPanel/YourSubscription";
import {AccountSettings} from "../components/Settings/UserPanel/AccountSettings";
import {Footer} from "../components/Footer";
import Container from "@mui/material/Container";
import {VerifyEmail} from "../pages/VerifyEmail";
import {HelpPage} from "../pages/HelpPage";
import {StripeSession} from "../pages/StripeSession";
import {UserPapers} from "../components/UserPapers";
import {UserPaper} from "../components/UserPaper";

export const websiteLinks = {
  home: '/',
  about: '/about',
  resources: '/what-is-rapidemia',
  forEducators: '/for-educators',
  contact: '/contact',
  privacy: '/privacy-policy',
  termsOfService: '/terms-of-service',
  acceptableUse: '/acceptable-use'
}

export const routeLinks = {
  login: "/login",
  signup: "/signup",
  resetPassword: "/resetPassword",
  pricing: "/pricing",
  checkout: "/checkout",
  subscriptionConfirmation: "/subscription/complete",
  userQuiz: "/quiz",
  settings: "/settings",
  accountSettings: "/settings/account",
  yourSubscription: "/settings/yourSubscription",
  subscriptionUsers: "/settings/subscriptionUsers",
  generatedPapers: "/settings/userPapers",
  generatedPaper: "/settings/userPaper/:paperId",
  browsePapers: "/",
  paper: "/paper/:paperId",
  verifyEmail: "/verifyEmail",
  helpPage: "/help",
}

export const Navigation = () => {
  let isDark = useMyThemeContext();
  let palette = getDesignByMode(isDark);
  let {isSchoolAdmin} = useUserRightContext();

  return <ThemeProvider theme={createTheme(palette)}>
    <CssBaseline/>
    <Router>
      <ResponsiveAppBar/>
      <Toolbar/>
      <Container maxWidth="xl" sx={{ paddingBottom: "45px", width: "100vw" }}>
        <Routes>
          <Route exact path={routeLinks.browsePapers} element={<BrowsePage/>}/>
          <Route path={routeLinks.login} element={<Login/>}/>
          <Route path={routeLinks.signup} element={<SignUp/>}/>
          <Route path={routeLinks.resetPassword} element={<ResetPassword/>}/>
          <Route path={routeLinks.checkout + '/:priceId'} element={<StripeSession/>}/>
          <Route path={routeLinks.subscriptionConfirmation} element={<SubscriptionConfirmation/>}/>
          <Route path={routeLinks.userQuiz} element={<UserQuiz/>}/>
          <Route path={routeLinks.paper} element={<PaperPage/>}/>
          <Route path={routeLinks.pricing} element={<Pricing/>}/>
          <Route path={routeLinks.verifyEmail} element={<VerifyEmail/>} />
          <Route path={routeLinks.helpPage} element={<HelpPage/>} />

          <Route path={routeLinks.settings} element={<Settings/>}>
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={routeLinks.accountSettings}*/}
            {/*  element={<AccountSettings/>}*/}
            {/*/>*/}
            <Route
              exact
              path={routeLinks.yourSubscription}
              element={<YourSubscription/>}
            />
            {isSchoolAdmin && <Route
              exact
              path={routeLinks.subscriptionUsers}
              element={<SubscriptionUsers/>}
            />}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={routeLinks.generatedPapers}*/}
            {/*  element={<UserPapers />}*/}
            {/*/>*/}
            <Route
              exact
              path={routeLinks.generatedPaper}
              element={<UserPaper />}
            />
          </Route>
          
          
          <Route path={"*"} element={<NotFound/>}/>
        </Routes>
      </Container>
      <Footer/>
    </Router>
  </ThemeProvider>
}