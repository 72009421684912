import React, {useCallback} from "react";
import Container from "@mui/material/Container";
import {Link as RouterLink, useParams} from "react-router-dom";
import {Box, Button, Grid, Modal, Typography} from "@mui/material";
import {faCopy} from "@fortawesome/free-regular-svg-icons/faCopy";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {usePaperContext} from "../../contexts/paperContext";
import {useUserRightContext} from "../../contexts/userRightContext";
import {ToAuthorString} from "../../utils/ToAuthorString";
import {FreePlanPrompt} from "../Pricing/FreePlanPrompt";
import {BottomDrawer} from "../Shared/BottomDrawer";
import {useTheme} from "@emotion/react";
import {Loading} from "../Shared/Loading";
import {UpOrDownGradePricingPlans} from "../Pricing/UpOrDownGradePricingPlans";
import {FieldFactory} from "./FieldFactory";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
};


export const Paper = () => {
  const [paper, setPaper] = React.useState();
  const [loaded, setLoaded] = React.useState(false);
  const [getPaperCount, setGetPaperCount] = React.useState(0);
  const [rateLimitHit, setRateLimitHit] = React.useState(false);

  const theme = useTheme();
  const {paperId} = useParams()
  const {getPaper} = usePaperContext();
  let {isLoggedIn, hasLoadedUser} = useUserRightContext();
  
  const reGetPaperInTime = () => {
    if (getPaperCount <= 3) {
      setTimeout(() => {
        getThePaper();
        
        // 30 seconds
      }, 30000)
    }
  }
  
  const getThePaper = () => {
    setGetPaperCount(getPaperCount + 1);
    getPaper(paperId)
      .then(resp => {
        setPaper(resp)
      })
      .catch(err => {
        if (err === "rate limit hit") {
          setRateLimitHit(true);
        }
        console.log("error", err);
      })
      .finally(() => {
        setLoaded(true);
      })
  }

  React.useEffect(() => {
    // wait for the user to have loaded to attempt to get the paper.
    if (!hasLoadedUser || !paperId) {
      return;
    }
    
    getThePaper();
  }, [paperId, hasLoadedUser]);
  
  const renderBodyElement = useCallback(() => {
    if (!isLoggedIn) {
      return <React.Fragment>
        <Grid item sx={{marginTop: "50px"}}/>
        <BottomDrawer>
          <FreePlanPrompt/>
        </BottomDrawer>
      </React.Fragment>;
    }
    
    if (paper.isOutOfPapers || paper.isPaperPreview) {
      return <React.Fragment>
        <Grid item sx={{marginTop: "300px"}}/>
        <BottomDrawer>
          <Grid container maxWidth="lg" sx={{alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px'}}>
            <Typography>You are out of papers for the month. Consider upgrading!</Typography>
            <UpOrDownGradePricingPlans/>
          </Grid>
        </BottomDrawer>
      </React.Fragment>
    }
    
    if (paper.isProcessing) {
      reGetPaperInTime();
      return <React.Fragment>
        <Grid container maxWidth="lg" sx={{ marginY: "150px", alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px' }}>
          <Typography variant="h4">We haven't thought about this paper yet. Give us a moment as we work on this paper.</Typography>
        </Grid>
      </React.Fragment>
    }
    
    let paperJson;
    try {
      paperJson = JSON.parse(paper.paperContent);
    }
    catch(err) {
    }
    
    if (!paperJson || !paperJson?.content) {
      return <React.Fragment>
        <Grid container maxWidth="lg" sx={{ marginY: "150px", alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px' }}>
          <Typography variant="h4">We had an issue getting this paper. Please try again later.</Typography>
        </Grid>
      </React.Fragment>
    }

    return <React.Fragment>
      <FieldFactory contents={paperJson.content} />
    </React.Fragment>
  }, [paper]);

  return <Container component="main" maxWidth="lg" sx={{paddingY: "65px"}}>
    <Modal
      open={rateLimitHit}
      onClose={() => setRateLimitHit(false)}
    >
      <Box sx={{ ...style, width: 'fit-content', maxWidth: "70vw", minWidth: "25vw" }}>
        <Typography>You have viewed more than 35 articles in the last hour. We will pause your account for an hour once you hit 50.</Typography>
        <Button onClick={() => setRateLimitHit(false)}>Close</Button>
      </Box>
    </Modal>
    {!loaded && <Loading/>}
    {loaded && paper == null && <Typography>Unable to find the paper. Please try again soon!</Typography>}
    {paper && <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h1" maxWidth="md" sx={{fontWeight: "700", fontSize: "3rem"}}>{paper?.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" maxWidth="md" sx={{fontWeight: "600", fontSize: "2rem"}}>{paper?.altTitle}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={(theme) => {
          return {color: theme.palette.font.grey, fontSize: "1.1rem"}
        }}>
          <span style={{fontWeight: "700"}}>Contributors: </span>
          {ToAuthorString(paper?.authors)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="img"
          src={"/brain.png"}
          sx={{
            height: 'fit-content',
            width: '100%',
            maxHeight: "512px",
            objectFit: 'cover'
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="secondary" sx={{fontWeight: "600", fontSize: ".9rem"}}>Summarized by</Typography>
            <Typography sx={(theme) => {
              return {color: theme.palette.font.grey, fontSize: "1rem", fontWeight: "500"}
            }}>Rapidemia AI</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="secondary" sx={{fontWeight: "600", fontSize: ".9rem"}}>Original Publish Date</Typography>
            <Typography sx={(theme) => {
              return {color: theme.palette.font.grey, fontSize: "1rem", fontWeight: "500"}
            }}>{paper?.publishDate}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} sx={{display: "flex", alignItems: "flex-start", justifyContent: "flex-end"}}>
        <Button
          fullWidth={theme.breakpoints.down('xs') ? true : false}
          variant="outlined"
          color="secondary"
          LinkComponent={RouterLink}
          to={paper.originalPaperLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faCopy} style={{marginRight: "10px"}}/>
          Link to Original Paper
        </Button>
      </Grid>
      <Grid item xs={12}>
        {
          renderBodyElement()
        }
      </Grid>

    </Grid>}
  </Container>
}