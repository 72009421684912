import React, {useEffect} from "react";
import {
  Autocomplete,
  Card,
  Divider,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import {useAxios} from "../../../hooks/useAxios";

export const PaperFilters = ({filters, changeTitleFilter, subjectOptionsUpdate}) => {
  const {aGet} = useAxios(false);
  
  const [paperSubjectOptions, setPaperSubjectOptions] = React.useState([
    {paperSubjectId: 8, label: "Medicine and health sciences"},
    {paperSubjectId: 92, label: "Neuroscience"},
    {paperSubjectId: 356, label: "Diagnostic medicine"},
    {paperSubjectId: 93, label: "Psychology"},
    {paperSubjectId: 1, label: "Biology and life sciences"}
  ]);
  
  const loadSubjects = async () => {
    await aGet("/PaperSubjects/all")
      .then((resp) => {
        if (resp.status === 200) {
          setPaperSubjectOptions(resp.data);
        } 
      })
  }
  
  useEffect(() => {
    loadSubjects();
  }, [])


  return <Card sx={{padding: '20px'}}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={(theme) => {
          return {fontSize: "1.2rem", fontWeight: "600", color: theme.palette.secondary.main}
        }}>Filters</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={(theme) => {
          return {fontSize: ".8rem", fontWeight: "600", marginBottom: "10px"}
        }}>Search Titles</Typography>
        <TextField
          id="Title"
          name="Title"
          value={filters.title}
          onChange={(e) => changeTitleFilter(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={(theme) => {
          return {fontSize: ".8rem", fontWeight: "600", marginBottom: "10px"}
        }}>Search Subjects</Typography>
        <Autocomplete renderInput={(params) => <TextField {...params} />} 
                      options={paperSubjectOptions}
                      id="filter-subjects"
                      multiple
                      value={filters.paperSubjectIds}
                      onChange={(event, newValue) => {
                        subjectOptionsUpdate(newValue)
                      }}
        />
      </Grid>
    </Grid>
  </Card>
}