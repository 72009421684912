import React, {useCallback} from "react";
import {useUserRightContext} from "../../contexts/userRightContext";
import {Link as RouterLink, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import {Box, Button, Grid, Modal, Typography} from "@mui/material";
import {Loading} from "../Shared/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons/faCopy";
import {FieldFactory} from "../Paper/FieldFactory";
import {useTheme} from "@emotion/react";

export const UserPaper = () => {
  const [genPaper, setGenPaper] = React.useState();
  const [loaded, setLoaded] = React.useState(false);

  const theme = useTheme();
  const {paperId} = useParams();
  let {isLoggedIn, hasLoadedUser} = useUserRightContext();

  const renderBodyElement = useCallback(() => {

    let paperJson;
    try {
      paperJson = JSON.parse(genPaper.OriginalText);
    }
    catch(err) {
    }


    if (!paperJson || !paperJson?.content) {
      return <React.Fragment>
        <Grid container maxWidth="lg" sx={{ marginY: "150px", alignContent: "center", justifyContent: "center", alignSelf: "center", paddingX: '20px' }}>
          <Typography variant="h4">We had an issue getting this paper. Please try again later.</Typography>
        </Grid>
      </React.Fragment>
    }

    return <React.Fragment>
      <FieldFactory contents={paperJson.content} />
    </React.Fragment>
  });

  return <Container component="main" maxWidth="lg" sx={{paddingY: "65px"}}>
    {/*<Modal*/}
    {/*  open={rateLimitHit}*/}
    {/*  onClose={() => setRateLimitHit(false)}*/}
    {/*>*/}
    {/*  <Box sx={{ ...style, width: 'fit-content', maxWidth: "70vw", minWidth: "25vw" }}>*/}
    {/*    <Typography>You have viewed more than 35 articles in the last hour. We will pause your account for an hour once you hit 50.</Typography>*/}
    {/*    <Button onClick={() => setRateLimitHit(false)}>Close</Button>*/}
    {/*  </Box>*/}
    {/*</Modal>*/}
    {!loaded && <Loading/>}
    {loaded && genPaper == null && <Typography>Unable to find the paper. Please try again soon!</Typography>}
    {genPaper && <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h1" maxWidth="md" sx={{fontWeight: "700", fontSize: "3rem"}}>{genPaper?.title}</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="secondary" sx={{fontWeight: "600", fontSize: ".9rem"}}>Summarized by</Typography>
            <Typography sx={(theme) => {
              return {color: theme.palette.font.grey, fontSize: "1rem", fontWeight: "500"}
            }}>Rapidemia AI</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="secondary" sx={{fontWeight: "600", fontSize: ".9rem"}}>Original Article Link</Typography>
            <Typography sx={(theme) => {
              return {color: theme.palette.font.grey, fontSize: "1rem", fontWeight: "500"}
            }}>{genPaper?.OriginalArticleLink}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} sx={{display: "flex", alignItems: "flex-start", justifyContent: "flex-end"}}>
        <Button
          fullWidth={theme.breakpoints.down('xs') ? true : false}
          variant="outlined"
          color="secondary"
          LinkComponent={RouterLink}
          to={genPaper.originalPaperLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faCopy} style={{marginRight: "10px"}}/>
          Link to Original Paper
        </Button>
      </Grid>
      <Grid item xs={12}>
        {
          renderBodyElement()
        }
      </Grid>

    </Grid>}
  </Container>
}