import React from "react";
import {Button, Container, Divider, Grid, Paper, Typography} from "@mui/material";
import {routeLinks, websiteLinks} from "../../navigation";
import {useNavigate} from "react-router-dom";
import Link from "../Shared/Link";

export const Footer = () => {
  const navigate = useNavigate();
  const linkStyles = {fontWeight: '600', display: "inline-block", paddingRight: "20px", fontSize: "13px"};

  return <Paper
    component="footer"
    variant="outlined"
    square
    sx={{ zIndex: '100' }}
  >
    <Container>
      <Grid container sx={{paddingY: '64px'}}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="div"
            sx={(theme) => ({flexGrow: 1, color: theme.palette.font.main, display: 'flex', fontWeight: 500})}
            onClick={() => navigate(routeLinks.browsePapers)}
          >
            <img src={'https://d28skfm1ycccok.cloudfront.net/rapidemia-white-logo.png'}
                 style={{width: '30px', height: '30px'}}/>
            Rapidemia
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} sx={{paddingTop: "14px"}}>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({...linkStyles, flexGrow: 1, color: theme.palette.font.grey})}>
              Home
            </Typography>
          </Link>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.about} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({...linkStyles, flexGrow: 1, color: theme.palette.font.grey})}>
              About
            </Typography>
          </Link>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.resources} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({...linkStyles, flexGrow: 1, color: theme.palette.font.grey})}>
              Resources
            </Typography>
          </Link>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.forEducators} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({...linkStyles, flexGrow: 1, color: theme.palette.font.grey})}>
              For Educators
            </Typography>
          </Link>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.contact} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({...linkStyles, flexGrow: 1, color: theme.palette.font.grey})}>
              Contact
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(routeLinks.helpPage)
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }}
            sx={{mt: 3, ml: 1}}
          >Get Help</Button>

        </Grid>
      </Grid>
      <Grid container alignItems='space-between'>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p" component="div"
                      sx={(theme) => ({
                        fontWeight: '600',
                        display: "inline-block",
                        paddingY: "20px",
                        fontSize: "11px",
                        flexGrow: 1,
                        color: theme.palette.font.grey,
                      })}>
            Copyright © 2023 Rapidemia. All rights reserved.
          </Typography>
        </Grid>
        <Grid item={12} md={6} display="flex" justifyContent="right">
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.termsOfService} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({
                          fontWeight: '600',
                          display: "inline-block",
                          paddingY: "20px",
                          fontSize: "11px",
                          flexGrow: 1,
                          color: theme.palette.font.grey,
                          textAlign: 'right'
                        })}>
              Terms of Service
            </Typography>
          </Link>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.acceptableUse} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({
                          fontWeight: '600',
                          display: "inline-block",
                          padding: "20px",
                          fontSize: "11px",
                          flexGrow: 1,
                          color: theme.palette.font.grey,
                          textAlign: 'right'
                        })}>
              Acceptable Use
            </Typography>
          </Link>
          <Link href={process.env.REACT_APP_WEBSITE_DOMAIN + websiteLinks.privacy} target="_blank">
            <Typography variant="p" component="div"
                        sx={(theme) => ({
                          fontWeight: '600',
                          display: "inline-block",
                          paddingY: "20px",
                          fontSize: "11px",
                          flexGrow: 1,
                          color: theme.palette.font.grey,
                          textAlign: 'right'
                        })}>
              Privacy
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Container>
  </Paper>
}