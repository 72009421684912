import React, {useEffect} from "react";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useAxios} from "../../../hooks/useAxios";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {routeLinks} from "../../../navigation";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {AddEditSubscriptionUsers} from "./AddEditSubscriptionUsers";
import {useToastify} from "../../../hooks/useToastify";

export const SubscriptionUsers = () => {
  const {aGet, aPost, aDelete} = useAxios(true);
  const navigate = useNavigate();
  const [subUsers, setSubUsers] = React.useState([]);
  const {successToast} = useToastify();

  // modal
  const [addEditModal, setAddEditModal] = React.useState(false);
  const [isEditModal, setIsEditModal] = React.useState(false);
  const [subUserModal, setSubUserModal] = React.useState({});

  let emptyUser = {userEmail: ""}

  const handleClose = () => {
    setAddEditModal(false);
    setSubUserModal(emptyUser);
  }

  const addUserSubClick = () => {
    setSubUserModal(emptyUser);
    setIsEditModal(false);
    setAddEditModal(true);
  }

  const editUserSubClick = (schoolUserId) => {
    let userOb = subUsers.find(u => u.schoolUserId === schoolUserId);
    setIsEditModal(true);
    setSubUserModal(userOb);
    setAddEditModal(true);
  }

  const deleteUserSubClick = (schoolUserId) => {
    aDelete("/SchoolUsers/deleteSubscriptionUser/" + schoolUserId)
      .then(() => {
        setAddEditModal(false);

        successToast("Successfully deleted user!");
        getSubscriptionUsers();
      })
  }

  const onSave = (user) => {
    aPost("/SchoolUsers/saveSubscriptionUser", user)
      .then((response) => {
        setSubUserModal(emptyUser);
        setAddEditModal(false);

        successToast("Successfully saved user!");
        getSubscriptionUsers();
      });
  }
  // end modal

  const getSubscriptionUsers = () => {
    aGet("/SchoolUsers/getSubscriptionUsers")
      .then((response) => {
        setSubUsers(response.data)
      })
      .catch(() => {
        throw Error();
      });
  }

  useEffect(() => {
    getSubscriptionUsers();
  }, []);

  return <React.Fragment>
    <Button onClick={() => addUserSubClick()}>Add New User</Button>
    {subUsers && subUsers.length > 0 &&
    <TableContainer component={Paper}>
      <Table aria-label="subscription-users">
        <TableHead>
          <TableRow>
            <TableCell align="center">Actions</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Has Registered</TableCell>
            <TableCell>Is Admin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subUsers.map((user) => {
            return <TableRow key={user.schoolUserId}>
              <TableCell align="center">
                <IconButton size='small' onClick={() => editUserSubClick(user.schoolUserId)}>
                  <FontAwesomeIcon icon={faPenToSquare}/>
                </IconButton>
                <IconButton size='small' onClick={() => deleteUserSubClick(user.schoolUserId)}>
                  <FontAwesomeIcon icon={faTrashCan}/>
                </IconButton>
              </TableCell>
              <TableCell>{user.userEmail}</TableCell>
              <TableCell>{user.hasRegistered ? "Yes" : "No"}</TableCell>
              <TableCell>{user.isAdmin ? "Yes" : "No"}</TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    }
    <AddEditSubscriptionUsers
      open={addEditModal}
      handleClose={handleClose}
      editUser={isEditModal}
      subUser={subUserModal}
      onSave={onSave}
    />
  </React.Fragment>
}