import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {routeLinks} from "../navigation";
import {useUser} from "../contexts/userContext";
import {GoogleLogin} from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import {useToastify} from "../hooks/useToastify";
import {LoadingBackdrop} from "../components/Shared/LoadingBackdrop";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {IconButton, InputAdornment} from "@mui/material";

export const Login = () => {
  const { logIn, logInGoogle, forgotPassword } = useUser();
  const blankUser = {
    email: "",
    password: "",
    rememberMe: true
  }
  const [formState, setFormState] = React.useState(blankUser);
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  
  const navigate = useNavigate();
  const {errorToast} = useToastify();
  
  const validateEmail = (val) => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    let isValid = val.match(emailRegex);
    setIsEmailValid(isValid);
    return isValid;
  }
  
  const onChangeEmail = (e) => {
    validateEmail(e.target.value);
    setFormState({ ...formState, email: e.target.value });
  }
  const onChangePassword = (e) => {
    setFormState({ ...formState, password: e.target.value });
  }
  const onRemeberMe = (checked) => {
    setFormState({ ...formState, rememberMe: checked });
  }
  
  const handleNativeUserSubmit = async (event) => {
    event.preventDefault();
    let validEmail = await validateEmail(formState.email);
    
    if (!validEmail)  return;

    setLoading(true);
    
    let uO = {
      email: formState.email,
      password: formState.password,
      rememberMe: formState.rememberMe
    }
    let response = await logIn(uO.email, uO.password, uO.rememberMe);
    if (response) {
      if (response?.status === 400) {
        if (response.data) {
          errorToast(response.data);
        } else {
          errorToast("Unable to log you in. Please try again.");
        }
      } else if (response.promptUserQuizOnLogin) {
        navigate(routeLinks.userQuiz);
      } else {
        navigate("/");
      }
    } else {
      errorToast("Unable to log you in. Please try again later.")
    }
    setLoading(false);
  };
  
  const handleForgotPassword = async () => {
    setLoading(true);
    let isEmailCorrect = await validateEmail(formState.email)
    if (isEmailCorrect) {
      setIsEmailValid(true);
      await forgotPassword(formState.email);
    } else {
      setIsEmailValid(false)
    }
    setLoading(false);
  }

  return (
      <Container component="main" maxWidth="xs" sx={{ paddingBottom: "45px" }}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {loading && <LoadingBackdrop loading={loading}/>}
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formState.email}
              onChange={(e) => onChangeEmail(e)}
              autoFocus
              error={!isEmailValid}
              helperText={!isEmailValid && "Please provide a valid email address."}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={formState.password}
              onChange={(e) => onChangePassword(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={<Checkbox checked={formState.rememberMe} onChange={(e) => onRemeberMe(e.target.checked)} value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(event) => handleNativeUserSubmit(event)}
            >
              Sign In
            </Button>
            {/*<GoogleLogin*/}
            {/*  text="signin_with"*/}
            {/*  onSuccess={credentialResponse => {*/}
            {/*    logInGoogle(credentialResponse.credential, "post", "something")*/}
            {/*    console.log(credentialResponse);*/}
            {/*  }}*/}
            {/*  onError={() => {*/}
            {/*    console.log('Google Login Failed');*/}
            {/*  }}*/}
            {/*/>*/}
            <Grid container style={{ paddingTop: "20px"}}>
              <Grid item xs>
                <Link onClick={(event) => handleForgotPassword(event)} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href={routeLinks.signup} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}

