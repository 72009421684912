import React from "react";
import {Button, Card, CardMedia, Chip, Grid, Tooltip, Typography} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {ToAuthorString} from "../../../utils/ToAuthorString";
import Container from "@mui/material/Container";
import {useUserRightContext} from "../../../contexts/userRightContext";
import {useNavigate} from "react-router-dom";
import {routeLinks} from "../../../navigation";
import { Link as RouterLink } from 'react-router-dom';


export const PaperCard = ({paper}) => {
  const navigate = useNavigate();
  let {isLoggedIn, isSchoolAdmin} = useUserRightContext();
  
  return <Card style={{display: 'flex', flexDirection: 'column'}}>
    <Container sx={{ position: 'relative', padding: '0 !important' }}>
      <Container sx={{ position: "absolute", width: 'fit-content', right: '0', padding: "6px 6px 0 0 !important" }}>
        <Chip label={paper.paperSubjects[0]} variant='filled' color="primary"/>
      </Container>
      <CardMedia
        sx={{height: 275, minHeight: 275}}
        image={paper.paperImageLink}
        title="green iguana"
      />
    </Container>
    <Grid style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      flexDirection: 'row',
      height: "100%",
      margin: "1px 16px 16px 1px",
      width: "100%"
    }} container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={(theme) => {
          return {color: theme.palette.font.grey, fontSize: ".9rem", fontWeight: "400"}
        }}>
          {paper.publishDate}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" maxWidth="md" sx={{fontWeight: "600", fontSize: "1.3rem"}}>
          {paper.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" maxWidth="md" sx={{fontWeight: "500", fontSize: "1rem"}}>
          {paper.altTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} zeroMinWidth>
        <Typography
          sx={(theme) => {
            return {color: theme.palette.font.grey, fontSize: ".9rem", fontWeight: "400", width: "95%"}
          }}
          noWrap
        >
          <Tooltip title={ToAuthorString(paper.authors)}>
            {ToAuthorString(paper.authors)}
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12} zeroMinWidth sx={{height: 45}}>
        <Button
          sx={(theme) => {
            return {
              color: theme.palette.secondary.main,
              fontSize: "1rem",
              fontWeight: "500",
              padding: 0,
              textTransform: "none",
              justifyContent: "flex-start",
              backgroundColor: 'transparent',
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }
          }}
          LinkComponent={RouterLink}
          disableRipple
          disableElevation
          to={routeLinks.paper.replace(":paperId", paper.paperId)}
        >
          {isLoggedIn ? 'View' : 'Preview'} the Paper <FontAwesomeIcon icon={faAngleRight} style={{paddingLeft: 10}}/>
        </Button>
      </Grid>
    </Grid>
  </Card>
}