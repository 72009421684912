import React, {useEffect} from "react";
import {Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link as RouterLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import {routeLinks} from "../navigation";
import {faFile, faUserGear, faUsersGear} from "@fortawesome/free-solid-svg-icons";
import {useUserRightContext} from "../contexts/userRightContext";

const drawerWidth = 240;

export const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let {isSchoolAdmin} = useUserRightContext();

  useEffect(() => {
    if (location.pathname.includes(routeLinks.accountSettings) ||
      location.pathname.includes(routeLinks.yourSubscription) ||
      (isSchoolAdmin && location.pathname.includes(routeLinks.subscriptionUsers)) ||
      location.pathname.includes(routeLinks.generatedPapers) ||
      location.pathname.includes(routeLinks.generatedPaper)
    )
      return;

    navigate(routeLinks.yourSubscription)
  }, [location])

  return <Box sx={{display: 'flex'}}>
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
        zIndex: "0"
      }}
    >
      <Toolbar/>
      <Box sx={{overflow: 'auto'}}>
        <List>
          {/*<ListItem disablePadding>*/}
          {/*  <ListItemButton component={RouterLink} to={routeLinks.accountSettings}*/}
          {/*                  selected={location.pathname.includes(routeLinks.accountSettings)}>*/}
          {/*    <ListItemIcon>*/}
          {/*      <FontAwesomeIcon icon={faUserGear}/>*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary="Account"/>*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}

          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to={routeLinks.yourSubscription}
                            selected={location.pathname.includes(routeLinks.yourSubscription)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUserGear}/>
              </ListItemIcon>
              <ListItemText primary="Your Subscription"/>
            </ListItemButton>
          </ListItem>

          {isSchoolAdmin && <ListItem disablePadding>
            <ListItemButton component={RouterLink} to={routeLinks.subscriptionUsers}
                            selected={location.pathname.includes(routeLinks.subscriptionUsers)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUsersGear}/>
              </ListItemIcon>
              <ListItemText primary="Subscription Users"/>
            </ListItemButton>
          </ListItem>}

          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to={routeLinks.generatedPapers}
                            selected={location.pathname.includes(routeLinks.generatedPapers)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faFile}/>
              </ListItemIcon>
              <ListItemText primary="Generated Papers"/>
            </ListItemButton>
          </ListItem>

        </List>
      </Box>
    </Drawer>

    <Box
      component="main" sx={{flexGrow: 1, p: 3}}
    >
      <Outlet/>
    </Box>
  </Box>
}