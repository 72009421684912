
export const apiFreeTier = "free";
export const apiBasicTier = "basic";
export const apiIntermediateTier = 'intermediate';
export const apiUnlimitedTier = "unlimited";

export const apiFreeTierId = 0;
export const apiBasicTierId = 1;
export const apiIntermediateTierId = 2;
export const apiUnlimitedTierId = 3;

export const freeTier = {
  title: 'Free',
  apiName: apiFreeTier,
  apiId: apiFreeTierId,
  price: 'free',
  priceId: process.env.REACT_APP_STRIPE_FREE_KEY,
  description: [
    '2 Articles per month'
  ],
  buttonText: 'Sign up for free',
  buttonVariant: 'outlined',
  hasButtonOnClick: false,
  buttonOnClick: () => {},
  disableButton: false,
  isCancel: true,
}

export const basicTier = {
  title: 'Curiosity',
  apiName: apiBasicTier,
  apiId: apiBasicTierId,
  price: '',
  priceId: process.env.REACT_APP_STRIPE_BASIC_KEY,
  description: [
    '15 Articles ad-free per month',
    'Basic chat and email support'
  ],
  buttonText: 'Get started',
  buttonVariant: 'outlined',
  hasButtonOnClick: false,
  buttonOnClick: () => {},
  disableButton: false,
  isCancel: false,
}

export const intermediateTier =  {
  title: 'Researcher',
  apiName: apiIntermediateTier,
  apiId: apiIntermediateTierId,
  subheader: 'Most popular',
  price: '',
  priceId: process.env.REACT_APP_STRIPE_INTERMEDIATE_KEY,
  description: [
    '45 Articles ad-free per month',
    //'All features of Curiosity plan',
    'Priority chat and email support'
  ],
  buttonText: 'Get started',
  buttonVariant: 'contained',
  isPopular: true,
  hasButtonOnClick: false,
  buttonOnClick: () => {},
  disableButton: false,
  isCancel: false,
}

export const unlimitedTier = {
  title: 'Savant',
  apiName: apiUnlimitedTier,
  apiId: apiUnlimitedTierId,
  price: '',
  priceId: process.env.REACT_APP_STRIPE_UNLIMITED_KEY,
  description: [
    'Unlimited ad-free access',
    // 'Save content to your Savant Gateway',
    'Priority service and support'
  ],
  buttonText: 'Get started',
  buttonVariant: 'outlined',
  hasButtonOnClick: false,
  buttonOnClick: () => {},
  disableButton: false,
  isCancel: false,
}

export const allTiers = [
  freeTier,
  basicTier,
  intermediateTier,
  unlimitedTier
];

export const paidTiers = [
  basicTier,
  intermediateTier,
  unlimitedTier
]