import React from "react";
import {FormControl, Grid, MenuItem, Select, TextField, Typography} from "@mui/material";

export const Subject =
  ({
     subjectOptions,
     subject,
     setSubject,
     subjectOther,
     setSubjectOther,
     isStudent,
     isEducator
   }) => {
    const teachOrStudy = (isStudent && isEducator) || (!isStudent && !isEducator)
      ? 'teach/study' :
      isEducator ? 'teach' : 'study';

    return <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>What subject do you {teachOrStudy}?</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Select
              labelId="subject"
              id="subject"
              value={subject}
              label=""
              onChange={(ev) => {
                setSubject(ev.target.value)
              }}
            >
              {subjectOptions && subjectOptions.map((option) => {
                return <MenuItem value={option.optionId}>{option.optionName}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        {subject === 0 && <React.Fragment>
          <Grid item xs={12}>
            <Typography>Would you mind telling us what subject?</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="subjectOther"
              name={"subjectOther"}
              value={subjectOther}
              fullWidth
              onChange={(ev) => {
                setSubjectOther(ev.target.value)
              }}
            />
          </Grid>
        </React.Fragment>}
      </Grid>
    </React.Fragment>
  }