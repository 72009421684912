import React, {useCallback, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from '@stripe/react-stripe-js';
import {useAxios} from "../hooks/useAxios";
import {useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import {Grid, Typography} from "@mui/material";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripeSession = () => {
  let {priceId} = useParams();
  const {aPost} = useAxios(true);
  const [error, setError] = useState("");

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return aPost('/Payment/createCheckoutSession', {priceId})
      .then((resp) => {
        console.log("payment session???", resp)
        return resp.data.clientSecret;
      })
      .catch((err) => {
        console.log("error", err)
        setError(err.response.data);
      })
  }, []);

  const options = {fetchClientSecret};

  return (
    <Container sx={{padding: "60px"}}>
      {!error ?
        <div id="checkout">
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
          >
            <EmbeddedCheckout/>
          </EmbeddedCheckoutProvider>
        </div>
        :
        <Grid container maxWidth="lg" sx={{
          marginY: "150px",
          alignContent: "center",
          justifyContent: "center",
          alignSelf: "center",
          paddingX: '20px'
        }}>
          <Typography variant="h4">{error}</Typography>
        </Grid>}
    </Container>
  )
}