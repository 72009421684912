import React from "react";
import {Button, Container, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routeLinks} from "../navigation";

export const SubscriptionConfirmation = () => {
  const navigate = useNavigate();
  return <Container maxWidth="md" component="main" sx={{paddingY: '30px'}}>
    <Stack spacing={2} alignItems="center">
      <h1>Thank you for subscribing!</h1>
      <Button variant="contained" onClick={() => navigate(routeLinks.browsePapers)}>Start Browsing Now!</Button>
    </Stack>
  </Container>
}