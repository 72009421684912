import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import Grid from '@mui/material/Grid';
import {PricingCard} from "./PricingCard";
import {allTiers, apiFreeTierId, apiUnlimitedTierId, paidTiers} from "./pricingTiers";
import {useUserRightContext} from "../../contexts/userRightContext";
import {Typography} from "@mui/material";
import {useAxios} from "../../hooks/useAxios";
import {useToastify} from "../../hooks/useToastify";
import {LoadingBackdrop} from "../Shared/LoadingBackdrop";
import {useUser} from "../../contexts/userContext";

export const UpOrDownGradePricingPlans = ({allowDowngrade = false}) => {
  const {subscriptionType} = useUserRightContext();
  const { setUserDetails } = useUser();
  const {aGet, aPost} = useAxios(true);
  const {successToast, errorToast} = useToastify();
  
  const [pricingTiers, setPricingTiers] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState('');
  
  const downOrUpGradePlan = (priceId) => {
    return aPost('/Payment/updateSubscription', { priceId })
      .then((resp) => {
        setLoading(true);
        successToast("We successfully changed your subscription.");
        setUserDetails();
      })
      .catch((err) => {
        errorToast("We were unable to change your subscription. " + err.response.data);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    if (pricingTiers.length <= 0) {
      setLoading(true);
      setPricingTiers([]);
      aGet("/Payment/pricingDetails")
        .then((resp) => {
          if (resp.status !== 200) {
            setError("There was an error fetching our pricing. Please check back later.")
            return;
          }

          let pricings = allTiers;

          for (let i = 0; i < pricings.length; i++) {
            let price = pricings[i];
            const respPrice = resp.data.find((x) => {
              return x.stripePriceId == price.priceId;
            });

            price.price = respPrice.stripePrice;
          }

          setPricingTiers(pricings);
        })
        .finally(() => {
          
          setLoading(false);
        })
    }
  }, [])
  
  const updatedTiers = useMemo(() => {
    setLoading(true);
    
    // rename current plan to your plan and disable the button.
    const currentPlan = "Your Current Plan";
    const downgradePlan = "Downgrade";
    const upgradePlan = "Upgrade";

    // TODO: API CALL FOR Pricing....
    let tiers = [];
    pricingTiers.forEach((tier) => {
      let returnTier = tier;
      let onFreeTier = subscriptionType === apiFreeTierId;
      returnTier.hasButtonOnClick = false;

      // TODO: add upgrade or downgrade onClick handlers.
      if (tier.apiId === subscriptionType) {
        returnTier.buttonText = currentPlan;
        returnTier.disableButton = true;
      } else if (tier.apiId < subscriptionType) {
        returnTier.buttonText = downgradePlan;
        returnTier.disableButton = false;
        if (!onFreeTier) {
          returnTier.hasButtonOnClick = true;
          returnTier.buttonOnClick = () => downOrUpGradePlan(returnTier.priceId);
        }
      } else if (tier.apiId > subscriptionType) {
        returnTier.buttonText = upgradePlan;
        returnTier.disableButton = false;
        if (!onFreeTier) {
          returnTier.hasButtonOnClick = true;
          returnTier.buttonOnClick = () => downOrUpGradePlan(returnTier.priceId);
        }
      }
      
      if (tier.isCancel && !onFreeTier) {
        returnTier.buttonText = "Cancel";
        returnTier.hasButtonOnClick = true;
        returnTier.buttonOnClick = () => {
          return aPost('/Payment/cancelSubscription', { })
            .then((resp) => {
              setLoading(true);
              setUserDetails();
              successToast("We successfully canceled your subscription.");
            })
            .catch((err) => {
              errorToast("We were unable to cancel your subscription. " + err.response.data);
            })
            .finally(() => {
              setLoading(false);
            })
        }
      }

      if (tier.apiId < subscriptionType) {
        if (allowDowngrade) {
          tiers.push(returnTier);
        }
      } else if (tier.apiId === subscriptionType && tier.apiId === apiFreeTierId && !allowDowngrade) {
        // do nothing.
      } else {
        tiers.push(returnTier);
      }
    })

    setLoading(false);
    return tiers;
  }, [subscriptionType, pricingTiers]);

  // should I show free as a button under all of this or with the tiered cards?

  return (
    <Grid container spacing={5} paddingY={4} alignItems="center" justifyContent="center">
      {
        !allowDowngrade && subscriptionType === apiUnlimitedTierId
          ? <Typography>You have unlimited papers. Thanks!</Typography>
          : updatedTiers.map((tier) => {
            return <PricingCard tier={tier} mdGridColumn={updatedTiers.length > 3 ? 3 : 4} key={tier.apiId}/>
          })
      }
      {loading && <LoadingBackdrop loading={loading}/>}
    </Grid>
  );
}