import React from "react";
import {Drawer, Toolbar} from "@mui/material";

export const BottomDrawer = ({children}) => {
  return <Drawer
    variant="permanent"
    anchor="bottom"
  >
    <Toolbar/>
    {children}
  </Drawer>
}