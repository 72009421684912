let queueCallStorage = {};

export function queueCall(index, callable, timeInMillis) {
  queueCallStorage[index] = setTimeout(callable, timeInMillis);
}

export function killQueue(index) {
  clearTimeout(queueCallStorage[index]);
}

export const delayFunction = (key, functionToCall, timeToWaitInMillis) => {
  killQueue(key);
  queueCall(key, functionToCall, timeToWaitInMillis);
}