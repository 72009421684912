import React from "react";
import {Navigation} from "./navigation";
import {MyThemeProvider} from './contexts/myThemeContext';
import UserProvider from "./contexts/userContext";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UserRightProvider} from "./contexts/userRightContext";

function App() {
  return (
    <div className="App" style={{"display": "grid", "grid-template-rows": "auto 1fr auto", "min-height": "100vh"}}>
      <MyThemeProvider>
        <UserRightProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <UserProvider>
              <Navigation/>
            </UserProvider>
          </GoogleOAuthProvider>
        </UserRightProvider>
        <ToastContainer/>
      </MyThemeProvider>
    </div>
  );
}

export default App;
